
import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')


export const staffCode = Cookies.get('eqrbcd')?CryptoJS.AES.decrypt(Cookies.get('eqrbcd'), 'equity_bank').toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('eqrbtk')?CryptoJS.AES.decrypt(Cookies.get('eqrbtk'), 'equity_bank').toString(CryptoJS.enc.Utf8):'null'


export const Usertype = Cookies.get('usertype')?Cookies.get('usertype'):'null'

export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 


/* 
   export  const ServerUrl = 'http://192.168.64.3/React/equitybank/backadmin/controller';
export  const PictureUrl = 'http://192.168.64.3/React/equitybank/backadmin/images';
export const DatabaseName = "royalbandk_db"  
export  const FrontEndUrl = 'http://192.168.64.3/React/equitybank/backadmin'; 
 */ 


export  const ServerUrl = 'https://backadmin.equityfinancebank.com/controller';
export  const PictureUrl = 'https://backadmin.equityfinancebank.com/images';
export const DatabaseName = "equity_db"  
export  const FrontEndUrl = 'https://backadmin.equityfinancebank.com';   




